import * as React from "react";
import { useTranslation } from "react-i18next";
import { FileUploader, IFileInput } from "../../../../atoms/FileUploader/FileUploader";
import { FileInputPreview } from "./FileInputPreview/FileInputPreview";
import { useCallback } from "react";

interface IProps {
    readonly onImageSelectionUpdated: (key: string, files: Array<IFileInput>) => void;
    readonly images: Record<string, Array<IFileInput>>;
    readonly imageKey: string;
    readonly uploadLabel?: string;
}

export const SingleSelector: React.FC<IProps> = ({ onImageSelectionUpdated, images, imageKey, uploadLabel }) => {
    const [t] = useTranslation();

    const onImageSelectionChanged = useCallback(
        (files: Array<IFileInput>) => {
            onImageSelectionUpdated(imageKey, files);
        },
        [onImageSelectionUpdated, images]
    );

    const onImageRemoved = useCallback(
        (removedIndex: number) => {
            const updatedImages = [...images[imageKey]];
            updatedImages.splice(removedIndex, 1);
            onImageSelectionUpdated(imageKey, updatedImages);
        },
        [images, imageKey, onImageSelectionUpdated]
    );

    const resolvedLabel = uploadLabel ?? t("createJobPage.stage2.uploadFileLabel");

    return (
        <>
            <FileUploader label={resolvedLabel} onFilesLoaded={onImageSelectionChanged} multiple={true} accept="image/*" />
            <FileInputPreview images={images[imageKey]} onImageRemoved={onImageRemoved} />
        </>
    );
};
