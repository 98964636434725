import { JobStyle } from "@headbot/library";
import * as React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../../../atoms/Button/PrimaryButton";
import { WhatToExpectAnime } from "../../../Instructions/WhatToExpectAnime/WhatToExpectAnime";
import { WhatToExpectBentoBuff } from "../../../Instructions/WhatToExpectBuff/WhatToExpectBentoBuff";
import { CreateGuestJobContext } from "../../CreateGuestJobContext";
import { CenteredP } from "../Stages.styles";

export const Stage1: React.FC = () => {
    const [t] = useTranslation();
    const { onContinue, style } = useContext(CreateGuestJobContext);
    const whatToExpect = style === JobStyle.Buff ? <WhatToExpectBentoBuff /> : <WhatToExpectAnime />;
    return (
        <>
            <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                <CenteredP>{t("createJobPage.stepProgress", { currentStep: 1 })}</CenteredP>

                {whatToExpect}
                <PrimaryButton onClick={onContinue} type="button">
                    {t("createJobPage.continue")}
                </PrimaryButton>
            </div>
        </>
    );
};
