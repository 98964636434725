import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useTranslation } from "react-i18next";

export const WhatToExpectBentoBuff: React.FC = () => {
    const [t] = useTranslation();
    return (
        <div className="bg-gray-50 py-24 sm:py-32">
            <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
                <p className="mx-auto mt-2 max-w-lg text-pretty text-center text-4xl font-medium tracking-tight text-gray-950 sm:text-5xl">
                    {t("buffPage.faq.whatToExpect")}
                </p>
                <div className="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-2 lg:grid-rows-2">
                    <div className="relative lg:row-span-2">
                        <div className="absolute inset-px rounded-lg bg-white lg:rounded-l-[2rem]"></div>
                        <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
                            <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                                <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950 max-lg:text-center">
                                    {t("buffPage.faq.goodPhotos")}
                                </p>
                                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                                    {t("buffPage.faq.goodPhotosDescription")}
                                </p>
                            </div>
                            <div className="relative min-h-[15rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                                <ul
                                    role="list"
                                    className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8 lg:p-4"
                                >
                                    <li className="relative">
                                        <StaticImage
                                            src="./goodOutput1.jpg"
                                            alt={t("buffPage.faq.goodAlt1")}
                                            width={128}
                                            height={192}
                                            imgStyle={{ borderRadius: "4%" }}
                                            className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
                                        />
                                    </li>
                                    <li className="relative">
                                        <StaticImage
                                            src="./goodOutput2.jpg"
                                            alt={t("buffPage.faq.goodAlt2")}
                                            width={128}
                                            height={192}
                                            className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
                                        />
                                    </li>
                                    <li className="relative">
                                        <StaticImage
                                            src="./goodOutput3.jpg"
                                            alt={t("buffPage.faq.goodAlt3")}
                                            width={128}
                                            height={192}
                                            imgStyle={{ borderRadius: "4%" }}
                                            className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"></div>
                    </div>

                    <div className="relative lg:row-span-2">
                        <div className="absolute inset-px rounded-lg bg-white lg:rounded-r-[2rem]"></div>
                        <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-r-[calc(2rem+1px)]">
                            <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                                <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950 max-lg:text-center">
                                    {t("buffPage.faq.weirdPhotos")}
                                </p>
                                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                                    {t("buffPage.faq.weirdPhotosDescription")}
                                </p>
                            </div>
                            <div className="relative min-h-[15rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                                <ul
                                    role="list"
                                    className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8 lg:p-4"
                                >
                                    <li className="relative">
                                        <StaticImage
                                            src="./weird1.jpg"
                                            alt={t("buffPage.faq.weirdAlt1")}
                                            width={128}
                                            height={192}
                                            imgStyle={{ borderRadius: "4%" }}
                                            className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
                                        />
                                    </li>
                                    <li className="relative">
                                        <StaticImage
                                            src="./weird2.jpg"
                                            alt={t("buffPage.faq.weirdAlt2")}
                                            width={128}
                                            height={192}
                                            className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
                                        />
                                    </li>
                                    <li className="relative">
                                        <StaticImage
                                            src="./weird3.jpg"
                                            alt={t("buffPage.faq.weirdAlt3")}
                                            width={128}
                                            height={192}
                                            imgStyle={{ borderRadius: "4%" }}
                                            className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-r-[2rem]"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
