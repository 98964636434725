import { AxiosProgressEvent } from "axios";
import * as React from "react";
import { ChangeEvent, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { PromiseFunction, executePromisesSequentially } from "../../../../../functions/executePromisesSequentially";
import { usePostGuestJob } from "../../../../../hooks/api/job/usePostGuestJob";
import { usePostGuestJobFile } from "../../../../../hooks/api/job/usePostGuestJobFile";
import { PrimaryButton } from "../../../../atoms/Button/PrimaryButton";
import { SecondaryButton } from "../../../../atoms/Button/SecondaryButton";
import { RadioButtonGroup } from "../../../../atoms/RadioButtonGroup/RadioButtonGroup";
import { IRadioButtonOption } from "../../../../atoms/RadioButtonGroup/ReadioButtonGroup.types";
import { CreateGuestJobContext } from "../../CreateGuestJobContext";
import { CenteredH1, CenteredP } from "../Stages.styles";

export const Stage3: React.FC = () => {
    const [progressPercent, setProgressPercent] = useState(0.0);
    const [t] = useTranslation();
    const { onBack, gender, setGender, onContinue, images, setGuestJobId, style } = useContext(CreateGuestJobContext);
    const { mutation: postGuestJobFile } = usePostGuestJobFile(() => {});
    const { mutation: postGuestJob } = usePostGuestJob(() => {});

    const [currentFileCount, setCurrentFileCount] = useState(1);

    const options: Array<IRadioButtonOption> = React.useMemo(
        () => [
            { key: "male", label: t("jobDetailsPage.male") },
            { key: "female", label: t("jobDetailsPage.female") },
            { key: "unspecified", label: t("jobDetailsPage.unspecified") },
        ],
        [t]
    );
    const onUploadProgress = useCallback(
        (progressEvent: AxiosProgressEvent) => {
            const total = progressEvent.total ?? Infinity;
            const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
            setProgressPercent(percentCompleted);
        },
        [setProgressPercent]
    );
    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setGender(e.target.value);
        },
        [setGender]
    );
    const onContinueClicked = useCallback(async () => {
        const { guestJobId } = await postGuestJob.mutateAsync({ gender, style });

        const fileUploadPromises: Array<PromiseFunction> = [];
        let count = 1;
        Object.keys(images).forEach((imageKey) => {
            const imageSet = images[imageKey];
            for (const image of imageSet) {
                const p = () =>
                    new Promise<void>(async (resolve) => {
                        await postGuestJobFile.mutateAsync({ guestJobId, file: image, fileKey: imageKey, onUploadProgress });
                        setCurrentFileCount(count++);
                        resolve();
                    });
                fileUploadPromises.push(p);
            }
        });

        await executePromisesSequentially(fileUploadPromises);
        onContinue();
        setGuestJobId(guestJobId);
    }, [postGuestJob, gender, style, images, onContinue, setGuestJobId, postGuestJobFile, onUploadProgress]);
    const totalImages = Object.values(images).flat().length;
    const progressText = t("createJobPage.stage3.uploadingFile", { totalFiles: totalImages, fileNumber: currentFileCount });
    if (postGuestJobFile.isLoading === true) {
        return (
            <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                <div className="relative pt-1">
                    <div className="flex mb-2 items-center justify-between">
                        <div>
                            <span className="mb-4 inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
                                {progressText}
                            </span>
                        </div>
                        <div className="text-right">
                            <span className="text-xs font-semibold inline-block text-blue-600">{progressPercent}%</span>
                        </div>
                    </div>
                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                        <div
                            style={{ width: `${progressPercent}%` }}
                            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-700"
                        ></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                <CenteredP>{t("createJobPage.stepProgress", { currentStep: 3 })}</CenteredP>
                <SecondaryButton onClick={onBack} type="button">
                    {t("createJobPage.back")}
                </SecondaryButton>
                <CenteredH1>{t("createJobPage.stage3.selectGender")}</CenteredH1>
                <RadioButtonGroup options={options} label={t("jobDetailsPage.selectGender")} onChange={onChange} selectedKey={gender} />
                <PrimaryButton onClick={onContinueClicked} type="button">
                    {t("createJobPage.continue")}
                </PrimaryButton>
            </div>
        </>
    );
};
