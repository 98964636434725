import * as React from "react";
import { IFileInput } from "../../../../atoms/FileUploader/FileUploader";
import { SingleSelector } from "./SingleSelector";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    readonly onImageSelectionUpdated: (key: string, files: Array<IFileInput>) => void;
    readonly images: Record<string, Array<IFileInput>>;
    readonly imageKeys: Array<string>;
}

export const MultiSelector: React.FC<IProps> = ({ onImageSelectionUpdated, imageKeys, images }) => {
    const [t] = useTranslation();
    const selectors = useMemo(
        () =>
            imageKeys.map((key, index) => {
                return (
                    <SingleSelector
                        key={key}
                        imageKey={key}
                        images={images}
                        onImageSelectionUpdated={onImageSelectionUpdated}
                        uploadLabel={t("createJobPage.stage2.uploadFaceLabel", { faceNumber: index + 1 })}
                    />
                );
            }),
        [imageKeys, images, onImageSelectionUpdated]
    );

    return <>{selectors}</>;
};
