import { JobStyle, TelemetryEvent } from "@headbot/library";
import * as React from "react";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Constants } from "../../../../../services/Constants/Constants";
import { telemetry } from "../../../../../services/Telemetry/Telemetry";
import { PrimaryButton } from "../../../../atoms/Button/PrimaryButton";
import { SecondaryButton } from "../../../../atoms/Button/SecondaryButton";
import { IFileInput } from "../../../../atoms/FileUploader/FileUploader";
import { WhatPhotosNeeded } from "../../../Instructions/WhatPhotosNeeded";
import { CreateGuestJobContext } from "../../CreateGuestJobContext";
import { CenteredH1, CenteredP } from "../Stages.styles";
import { MultiSelector } from "./MultiSelector";
import { SingleSelector } from "./SingleSelector";

export const Stage2: React.FC = () => {
    const [t] = useTranslation();
    const { onBack, onContinue, images, setImages, style } = useContext(CreateGuestJobContext);

    const imageCount = Object.values(images).reduce((acc, imageSet) => acc + imageSet.length, 0);
    const isContinueDisabled = imageCount < Constants.MinimumRequiredImages;

    useEffect(() => {
        telemetry.Log(TelemetryEvent.CreateJobStage2);
    }, []);

    const shouldShowMultiSelector = style === JobStyle.Couple;

    const onImageSetUpdated = useCallback(
        (key: string, newImages: Array<IFileInput>) => {
            const updatedImages = { ...images, [key]: newImages };
            setImages(updatedImages);
        },
        [setImages, images]
    );

    return (
        <>
            <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                <CenteredP>{t("createJobPage.stepProgress", { currentStep: 2 })}</CenteredP>
                <SecondaryButton onClick={onBack} type="button">
                    {t("createJobPage.back")}
                </SecondaryButton>
                <CenteredH1>{t("createJobPage.stage2.uploadPhotos")}</CenteredH1>
                <WhatPhotosNeeded />
                {shouldShowMultiSelector ? (
                    <MultiSelector onImageSelectionUpdated={onImageSetUpdated} images={images} imageKeys={["face1", "face2"]} />
                ) : (
                    <SingleSelector imageKey="face1" onImageSelectionUpdated={onImageSetUpdated} images={images} />
                )}
                <PrimaryButton onClick={onContinue} type="button" disabled={isContinueDisabled}>
                    {t("createJobPage.continue")}
                </PrimaryButton>
            </div>
        </>
    );
};
